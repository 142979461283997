import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//Styled components imports
import Wrapper from "./Wrapper"
import Link from "./Link"

//React components imports
import GalleryPreview from "./GalleryPreview"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    {
      izby: file(
        relativePath: { eq: "gallery/izby-hotel-dolina/IMG_9598.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kaviaren: file(
        relativePath: {
          eq: "gallery/kaviaren-a-jedalen/kaviaren-a-jedalen-3.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Link to="galeria/izby-hotel-dolina">
        <GalleryPreview
          title="Izby v hotel Dolina"
          image={data.izby.childImageSharp.fluid}
          big
        />
      </Link>
      <Link to="galeria/kaviaren-a-jedalen">
        <GalleryPreview
          title="Kaviareň a jedáleň"
          image={data.kaviaren.childImageSharp.fluid}
        />
      </Link>
    </Wrapper>
  )
}

export default Gallery
