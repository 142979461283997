import React, { useContext, useEffect } from "react"
import Context from "../store/Context"

//React components imports
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Gallery from "../components/Gallery"
import Seo from "../components/Seo"

const GalleryPage = props => {
  const { dispatch } = useContext(Context)

  useEffect(() => {
    dispatch({ type: "SET_PATH", path: props.location.pathname })
  }, [dispatch, props.location.pathname])
  return (
    <Layout>
      <Seo
        title="Hotel Dolina - Galéria"
        description="Galéria hotelu Dolina"
        url="http://www.hoteldolina.eu/galeria"
        keywords={["galeria hotel dolina"]}
      />
      <PageTitle>Galéria Hotel Dolina</PageTitle>
      <Gallery />
    </Layout>
  )
}

export default GalleryPage
