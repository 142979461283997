import React from "react"

//Styled components imports
import Image from "./Image"
import Title from "./Title"

const GalleryPreview = props => {
  return (
    <Image fluid={props.image}>
      <Title big={props.big ? true : false}>{props.title}</Title>
    </Image>
  )
}

export default GalleryPreview
